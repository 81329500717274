<template>
  <section class="news-categories">
    <div class="w-100 mt-3">
      <custom-filter @handleSearch="fetchList" @handleAdd="handleAdd"></custom-filter>
      <b-card>
        <b-table :items="datas" :fields="fields">
          <template #cell(photo)="{item}">
            <img :src="$backendHost+item.photo" width="100" alt="">
          </template>
          <template #cell(status)="data">
            <b-form-checkbox v-model="data.item.status" name="check-button" switch @input="getStatusChange(data.item)">
            </b-form-checkbox>
          </template>
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button variant="success" size="sm" @click="$router.push({name: 'NominationContents', params:{id: data.item.id}})" >
                <b-icon icon="book"></b-icon>
              </b-button>
              <b-button variant="warning" size="sm" class="ml-1" @click="$router.push({name:'NominationQuestions',params:{id:data.item.id}})" >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button variant="primary" class="ml-1" size="sm" @click="handleEdit(data.item.id)" >
                <b-icon icon="pencil"></b-icon>
              </b-button>
              <b-button class="ml-1" variant="danger" size="sm" @click="handleDelete(data.item.id)" >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end mt-3">
          <b-pagination
              v-model="pagination.page"
              hide-goto-end-buttons
              :total-rows="total"
              :per-page="pagination.pageSize"
              @input="fetchList()"
          />
        </div>
      </b-card>
    </div>
    <b-modal id="modal-lg" size="lg" hide-footer :title="modalTitle" v-model="modalVisible" >
      <NominationForm :id="id" @saved="fetchList" />
    </b-modal>
  </section>
</template>

<script>
import {getNominations, deleteNomination, changeStatus} from '@/api/nomination';
import { listTemplate } from '@/utils/mixins/listTemplate'
import CustomFilter from '@/views/components/CustomFilter'
import NominationForm from '@/views/nominations/NominationForm'

const actions = {
  get: getNominations,
  delete: deleteNomination,
}

export default {
  name: 'Nomination',
  components:{
    CustomFilter,
    NominationForm,
  },
  mixins:[
    listTemplate,
  ],
  data() {
    return {
      actions,
      fields: [
        {key:'id', label: this.$t('message.ID')},
        {key:'photo', label: this.$t('message.Photo')},
        {key:'title', label: this.$t('message.Title')},
        {key:'status', label: this.$t('message.Status')},
        {key:'action', label: this.$t('message.Action')},
      ]
    }
  },
  methods: {
    getStatusChange(data) {
      console.log(data);
      changeStatus({id:data.id, status: data.status}).then(res => {
        this.$bvToast.toast('Changed', {
          title: 'Changed successfully',
          autoHideDelay: 5000,
          variant: 'success',
          appendToast: false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
