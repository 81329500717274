<template>
  <section class="news-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <validation-observer ref="observer" class="w-100">
      <b-row>
        <b-col cols="6">
          <div class="w-100">
            <b-form-group :label="$t('message.Photo')">
              <div v-if="form.photo" class="my-1">
                <img v-if="typeof form.photo === 'string'" :src="`${$backendHost}${form.photo}`" width="150" alt="">
              </div>
              <input type="file" @change="getNewsPhoto">
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
<!--          <div class="w-100">-->
<!--            <b-form-group :label="$t('message.Experience')">-->
<!--              <b-form-select v-model="form.experience_id" :options="experiences" text-field="name" value-field="id"></b-form-select>-->
<!--            </b-form-group>-->
<!--          </div>-->
        </b-col>
      </b-row>
      <div class="w-100">
        <b-form-group :label="$t('message.Experience')">
          <v-select :options="experiences" v-model="form.nomination_experiences" label="name" multiple></v-select>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-form-group :label="$t('message.SchoolType')">
          <v-select :options="school_types" label="name" v-model="form.school_type" multiple></v-select>
        </b-form-group>
      </div>
      <div class="w-100">
        <b-row>
          <b-col cols="6">
            <b-form-group :label="$t('message.StartAge')">
              <b-input type="number" v-model="form.age_start"></b-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :label="$t('message.EndAge')">
              <b-input type="number" v-model="form.age_end"></b-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-tabs content-class="pt-1" fill class="mt-2">
        <b-tab title="Uz">
          <div class="w-100">
            <b-form-group :label="$t('message.TitleUz')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.TitleUz')"
                  rules="required"
              >
                <b-input v-model="form.title_uz" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-form-group :label="$t('message.DescriptionUz')">
                <quill-editor
                    v-model="form.description_uz"
                    :options="snowOption"
                />
            </b-form-group>
          </div>
        </b-tab>
        <b-tab title="Ru">
          <div class="w-100">
            <b-form-group :label="$t('message.TitleRu')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.TitleRu')"
                  rules="required"
              >
                <b-input v-model="form.title_ru" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-form-group :label="$t('message.DescriptionRu')">
                <quill-editor
                    v-model="form.description_ru"
                    :options="snowOption"
                />
            </b-form-group>
          </div>
        </b-tab>
        <b-tab title="En">
          <div class="w-100">
            <b-form-group :label="$t('message.TitleEn')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.TitleEn')"
                  rules="required"
              >
                <b-input v-model="form.title_en" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-form-group :label="$t('message.DescriptionEn')">
                <quill-editor
                    v-model="form.description_en"
                    :options="snowOption"
                />
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
    </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="success" @click="saveSubmit" >{{$t('message.Add')}}</b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { storeNomination, updateNomination, showNomination } from '@/api/nomination'
import { getExperiences } from '@/api/experience'
import {getSchoolTypes} from '@/api/users'
import { formTemplate } from '@/utils/mixins/formTemplate';
import { quillEditor }  from 'vue-quill-editor';
import vSelect from 'vue-select';

function initForm(data) {
  const get = (key, value = null) => _.get(data,key,value);

  return {
    id: get('id', null),
    photo: get('photo', null),
    experience_id: get('experience_id', null),
    age_start: get('age_start', null),
    age_end: get('age_end', null),
    title_uz: get('title_uz', null),
    title_ru: get('title_ru', null),
    title_en: get('title_en', null),
    description_uz: get('description_uz', null),
    description_ru: get('description_ru', null),
    description_en: get('description_en', null),
    school_type: get('school_type',[]),
    nomination_experiences: get('nomination_experiences', [])
  }
}

const actions = {
  add:storeNomination,
  update: updateNomination,
  show: showNomination,
}

export default {
  name: 'NominationForm',
  components:{
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
  },
  mixins: [
    formTemplate,
  ],
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      experiences: [],
      school_types: [],
    }
  },
  created() {
    getExperiences().then(res => {
      this.experiences = res.data.data.map(item => Object.assign({},{id:item.id, name: item[`name_${this.$i18n.locale}`]}))
    });
    this.fetchSchoolTypes()
  },
  methods: {
    reformatData() {
      this.form.school_type = JSON.stringify(this.form.school_type.map(item => item.id));
      this.form.nomination_experiences = JSON.stringify(this.form.nomination_experiences.map(item => item.id));
      let form = this.prepareFormData(this.form);
      return form;
    },
    setForm(data) {
      this.form = initForm(data);
    },
    getNewsPhoto(e) {
      this.form.photo = e.target.files[0];
    },
    fetchSchoolTypes() {
      getSchoolTypes().then(res => {
        this.school_types = res.data.data;
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/quill.scss';
</style>
